<template>
  <div>
    <h4>Cities</h4>

    <div class="card mt-4">
      <div class="card-body">
        <h4>Create new City</h4>
        <FormInput
          v-model="form.name"
          label="City Name"
        />
        <div
          class="btn btn-success btn-sm btn-block mt-3"
          @click="sendForm"
        >
          <i class="fas fa-save mr-3" />
          Save
        </div>
      </div>
    </div>
    <div class="card mt-2">
      <div class="card-body">
        <h4>List</h4>
        <vue-table
          v-if="vTable.headers"
          ref="vtable"
          :values="cities"
          :headers="vTable.headers"
          :actions="vTable.actions"
          :options="vTable.options"
          @changed="getData"
          @editableInput="onChangeEditable"
          @onPressSave="onPressSave"
          @onDelete="onDelete"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FormInput } from '@/components/inputs';

export default {
  components: {
    FormInput,
  },
  data: () => ({
    form: {},
    vTable: {
      headers: [
        {
          title: 'id',
          sortable: true,
        },
        {
          title: 'name',
          sortable: true,
          editable: 'text',
        },
      ],
      actions: [
        {
          buttonClass: 'btn-success btn-sm',
          callback: 'onPressSave',
          tooltip: 'Save',
          icon: 'fas fa-save',
        },
        {
          buttonClass: 'btn-danger btn-sm',
          callback: 'onDelete',
          tooltip: 'Delete',
          icon: 'fas fa-times',
        },
      ],
      options: {
        tableClass: 'table table-striped',
      },
    },
  }),
  computed: {
    ...mapGetters([
      'cities',
    ]),
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    ...mapActions([
      'getCities',
      'createCity',
      'updateCity',
      'deleteCity',
    ]),
    getData(evt = {}) {
      this.getCities(evt).catch(this.showError);
    },
    resetTable() {
      this.$refs.vtable.init();
    },
    onChangeEditable(index, column, value) {
      const city = this.cities.data[index];
      city[column] = value;
      city.edited = true;
    },
    onPressSave(item) {
      if (item.edited) {
        this.updateCity(item)
          .then(() => {
            this.$noty.success('City updated succesfully');
            item.edited = false;
          })
          .catch(this.showError);
      } else {
        this.$noty.info('Edit some value to be able to save this item.');
      }
    },
    sendForm() {
      this.createCity(this.form)
        .then(() => {
          this.$noty.success('City created successfully');
          this.resetTable();
          this.form = {};
        })
        .catch(this.showError);
    },
    onDelete(item) {
      this.fireConfirm().then(() => {
        this.deleteCity(item)
          .then(() => {
            this.$noty.success('City deleted succesfully');
            this.resetTable();
          })
          .catch(this.showError);
      });
    },
  },
};
</script>

<style>

</style>
